import React from 'react'
import { Link } from 'gatsby'
import get from 'lodash/get'
import cx from 'classnames'
import {
  faTimes,
  faHome,
  faBriefcase,
  faEnvelope,
  faMobile
} from '@fortawesome/free-solid-svg-icons'
import {
  faFacebookSquare,
  faTwitter,
  faInstagram
} from '@fortawesome/free-brands-svg-icons'
import Icon from 'src/components/Icon/Icon'
import MenuItem from 'src/components/Menu/MenuItem.js'
import useApp from 'src/hooks/useApp'
import { HOME, CAREERS, CONTACT, PRODUCTS } from 'src/constants/pages'
import iconMenuPiccollage from '../../images/icon_menu_piccollage.png'
import styles from './Menu.module.scss'

const pages = [
  {
    name: HOME,
    path: '/',
    icon: faHome
  },
  {
    name: 'PicCollage',
    externalUrl: 'https://piccollage.com/',
    image: iconMenuPiccollage
  },
  {
    name: PRODUCTS,
    path: `/${PRODUCTS}`,
    icon: faMobile
  },
  {
    name: CAREERS,
    path: `/${CAREERS}`,
    icon: faBriefcase
  }
]
const socialLinks = [
  {
    url: 'https://piccollage.com',
    imageUrl: 'https://cb.pic-collage.com/website/layout/pic-collage-icon.png'
  },
  {
    url: 'https://www.facebook.com/piccollage',
    icon: faFacebookSquare
  },
  {
    url: 'https://twitter.com/piccollage',
    icon: faTwitter
  },
  {
    url: 'https://instagram.com/piccollage',
    icon: faInstagram
  }
]

const Menu = () => {
  const { state, closeMenu } = useApp()
  const isMenuOpen = get(state, 'isMenuOpen', false)

  const rootStyle = cx({
    [styles.root]: true,
    [styles.active]: isMenuOpen
  })

  return (
    <nav className={rootStyle}>
      <header className={styles.header}>
        <Icon
          icon={faTimes}
          handleClick={closeMenu}
          iconStyle={styles.closeIcon}
        />
      </header>
      <ul className={styles.menuList}>
        {pages.map(({ name, path, icon, externalUrl, image }) => (
          <MenuItem
            key={name}
            name={name}
            path={path}
            icon={icon}
            externalUrl={externalUrl}
            image={image}
          />
        ))}
      </ul>
      <div className={styles.socialLinkWrapper}>
        {socialLinks.map(({ url, imageUrl, icon }) => {
          return (
            <a
              key={url}
              target="_blank"
              rel="noopener noreferrer"
              href={url}
              className={styles.socialLink}
            >
              {icon ? (
                <Icon icon={icon} iconStyle={styles.socialIcon} />
              ) : (
                <img src={imageUrl} className={styles.socialImg} />
              )}
            </a>
          )
        })}
      </div>
      <footer className={styles.footer}>
        <p className={styles.footerDesc}>Cardinal Blue Software, Inc. </p>
        <p className={styles.footerDesc}>
          Copyright {new Date().getFullYear()}. All rights reserved.
        </p>
        <div>
          <Link to="/tos" className={styles.footerLink}>
            Terms of Service
          </Link>
          <Link to="/privacy" className={styles.footerLink}>
            Privacy Policy
          </Link>
        </div>
      </footer>
    </nav>
  )
}

export default Menu
