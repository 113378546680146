import { useContext } from 'react'
import { AppContext } from 'src/contexts/appContext'

const useApp = () => {
  const [state, setState] = useContext(AppContext)

  const openMenu = () =>
    setState(state => ({
      ...state,
      isMenuOpen: true
    }))

  const closeMenu = () =>
    setState(state => ({
      ...state,
      isMenuOpen: false
    }))

  return {
    state,
    openMenu,
    closeMenu
  }
}

export default useApp
