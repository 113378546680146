import React from 'react'
import queryAllAddressImages from 'src/queries/queryAllAddressImages'
import Img from 'gatsby-image'
import App from 'src/components/App/App'
import Head from 'src/components/Head/Head'
import Header from 'src/components/Header/Header'
import styles from './address.module.scss'

const getImage = (collection, name) => collection[name].childImageSharp.fluid

const Address = () => {
  const nodes = queryAllAddressImages()

  const mapImage = getImage(nodes, 'map')

  return (
    <App>
      <Head />
      <Header pageTitle="Address" />
      <main className={styles.main}>
        <section className={styles.sectionWrapper}>
          <article>
            <h3 className={styles.title}>
              PicCollage (San Francisco Bay Area)
            </h3>
            <p className={styles.content}>
              Our mailing address is:
              <br />
              P.O. Box 390677
              <br />
              Mountain View, CA 94039
            </p>
          </article>
          <article>
            <h3 className={styles.title}>PicCollage Office (Taipei)</h3>
            <p className={styles.content}>
              <a
                href="http://maps.google.com.tw/maps?q=106台北市大安區光復南路102"
                target="_blank"
                className={styles.link}
              >
                106台北市大安區光復南路102號11樓
              </a>
              <br />
              國父紀念館站1號出站，看到Starbucks右轉，經過7-11馬上左轉，從Chin
              Chin咖啡廳旁大樓搭電梯至11樓
            </p>
          </article>
          <article>
            <p className={styles.content}>
              <a
                href="http://maps.google.com.tw/maps?q=106台北市大安區光復南路102"
                target="_blank"
                className={styles.link}
              >
                11F, No. 102, Guangfu S. Road, Taipei, Taiwan
              </a>
              <br />
              Exit from SYS Memorial MRT Station, Exit 1. When you see
              Starbucks, make a right turn into the alley, and then after you
              pass 7-11, make your first left. When you reach the CTS building
              with the Chin Chin cafe, take the elevator to the 11th floor, and
              look for the office with the red wall and "PicCollage" sign.
            </p>
          </article>
        </section>
        <section className={styles.sectionWrapper}>
          <a
            href="http://maps.google.com.tw/maps?q=106台北市大安區光復南路102"
            target="_blank"
          >
            <Img fluid={mapImage} objectFit="cover" objectPosition="50% 50%" />
          </a>
        </section>
      </main>
    </App>
  )
}

export default Address
