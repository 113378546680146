import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import styles from './Icon.module.scss'

const Icon = props => {
  const { icon, handleClick, iconStyle, touchAreaStyle } = props

  const iconTouchAreaClassName = cx({
    [styles.iconTouchArea]: true,
    [touchAreaStyle]: true
  })
  const iconClassName = cx({
    [styles.icon]: true,
    [iconStyle]: true
  })

  return (
    <div className={iconTouchAreaClassName}>
      <FontAwesomeIcon
        icon={icon}
        onClick={handleClick}
        className={iconClassName}
      />
    </div>
  )
}

Icon.prototypes = {
  icon: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
  iconStyle: PropTypes.string.isRequired,
  touchAreaStyle: PropTypes.string.isRequired
}

export default Icon
