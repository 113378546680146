import { graphql, useStaticQuery } from 'gatsby'

const queryAllAddressImages = () => {
  return useStaticQuery(
    graphql`
      query {
        map: file(relativePath: { eq: "address_map.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )
}

export default queryAllAddressImages
