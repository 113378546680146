import React from 'react'
import {
  faFacebookSquare,
  faTwitter,
  faInstagram
} from '@fortawesome/free-brands-svg-icons'
import Icon from 'src/components/Icon/Icon'
import styles from './Footer.module.scss'

const socialLinks = [
  {
    url: 'https://www.facebook.com/piccollage',
    icon: faFacebookSquare,
    name: 'faFacebookSquare'
  },
  {
    url: 'https://twitter.com/piccollage',
    icon: faTwitter,
    name: 'faTwitter'
  },
  {
    url: 'https://instagram.com/piccollage',
    icon: faInstagram,
    name: 'faInstagram'
  }
]

const Footer = () => {
  return (
    <footer className={styles.root}>
      <p className={styles.desc}>Copyright {new Date().getFullYear()}. All rights reserved.</p>
      <ul className={styles.listWrapper}>
        {socialLinks.map(({ url, icon, name }) => (
          <li key={url} className={styles.list}>
            <a
              key={url}
              target="_blank"
              rel="noopener noreferrer"
              href={url}
              className={styles.link}
            >
              <Icon
                icon={icon}
                iconStyle={styles.icon}
                touchAreaStyle={styles[name]}
              />
            </a>
          </li>
        ))}
      </ul>
    </footer>
  )
}

export default Footer
