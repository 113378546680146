import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import isProduction from 'src/utils/env'
import path from 'src/utils/getPathName'
import getCdnImage from 'src/utils/getCdnImage'

const Head = props => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const title = get(
    data,
    'site.siteMetadata.title',
    'please check title in gatsby-config.js'
  )

  const description = get(
    data,
    'site.siteMetadata.description',
    'please check description in gatsby-config.js'
  )

  const ogData = {
    'og:title': path.getIsCareesPage() ? 'PicCollage is hiring' : title,
    'og:image': path.getIsCareesPage()
      ? getCdnImage('/website/jobs/careers-team-photo.png')
      : getCdnImage('/website/home/og-image.jpg'),
    'og:description': path.getIsCareesPage()
      ? 'PicCollage 拼貼趣 是一支擁抱自由創造、擁抱差異與多元文化，致力於打造『開放、學習、分享』的工作環境。 我們使用友善的設計及嚴謹的軟體技術， 也積極開發 3D、AR/VR、Machine Learning 相關科技，讓人們用創意拼貼生活中值得...'
      : description,
    'og:type': 'website'
  }

  const { pageTitle } = props

  return (
    <Helmet>
      <html lang="en" />
      <meta charset="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1"
      />
      <title>{!!pageTitle ? `${pageTitle} | ${title}` : title}</title>
      <meta name="description" content={ogData['og:description']} />

      <meta property="og:title" content={ogData['og:title']} />
      <meta property="og:description" content={ogData['og:description']} />
      <meta property="og:image" content={ogData['og:image']} />
      <meta property="og:type" content={ogData['og:type']} />

      {isProduction && (
        <link rel="canonical" href="https://cardinalblue.com/" />
      )}
      {!isProduction && <meta name="robots" content="noindex,nofollow" />}
      {props.children}
    </Helmet>
  )
}

export default Head
