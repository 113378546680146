import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Icon from 'src/components/Icon/Icon'
import capitalize from 'src/utils/capitalize'
import styles from './MenuItem.module.scss'

const MenuItem = props => {
  const { name, path, icon, externalUrl, image } = props

  const itemIcon = icon ? (
    <Icon icon={icon} iconStyle={styles.icon} />
  ) : (
    <div className={styles.linkImgWrapper}>
      <img src={image} className={styles.linkImg} />
    </div>
  )

  const itemLink = path ? (
    <Link to={path} activeClassName={styles.active} className={styles.link}>
      {itemIcon}
      <span className={styles.desc}>{capitalize(name)}</span>
    </Link>
  ) : (
    <a href={externalUrl} target="_blank" className={styles.link}>
      {itemIcon}
      <span className={styles.desc}>{capitalize(name)}</span>
    </a>
  )

  return <li className={styles.root}>{itemLink}</li>
}

MenuItem.propTypes = {
  path: PropTypes.string,
  name: PropTypes.string.isRequired,
  icon: PropTypes.object,
  externalUrl: PropTypes.string,
  image: PropTypes.string
}

export default MenuItem
