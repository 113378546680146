import React, { useEffect } from 'react'
import WebFont from 'webfontloader'
import { AppProvider } from 'src/contexts/appContext'
import Footer from 'src/components/Footer/Footer'
import Menu from 'src/components/Menu/Menu.js'
import MenuIcon from 'src/components/MenuIcon/MenuIcon'
import 'normalize.css'
import './App.module.scss'

const App = props => {
  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          'Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900italic,900',
          'Raleway:400,500,700'
        ]
      }
    })
  }, [])

  return (
    <AppProvider>
      <Menu />
      <MenuIcon />
      {props.children}
      <Footer />
    </AppProvider>
  )
}

export default App
