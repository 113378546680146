import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './Header.module.scss'

const Header = props => {
  const { pageTitle, color } = props

  const titleStyle = cx({
    [styles.title]: true,
    [styles.light]: color === 'light'
  })

  return (
    <header className={styles.root}>
      <div className={styles.wrapper}>
        <h1 className={titleStyle}>{pageTitle}</h1>
      </div>
    </header>
  )
}

Header.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  color: PropTypes.string
}

Header.defaultProps = {
  color: ''
}

export default Header
