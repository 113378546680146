import React from 'react'
import cx from 'classnames'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import useApp from 'src/hooks/useApp'
import path from 'src/utils/getPathName'
import Icon from 'src/components/Icon/Icon'
import styles from './MenuIcon.module.scss'

const MenuIcon = () => {
  const { openMenu } = useApp()

  return (
    <div className={styles.root}>
      <Icon icon={faBars} iconStyle={styles.icon} handleClick={openMenu} />
    </div>
  )
}

export default MenuIcon
